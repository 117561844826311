import React from 'react'
import { PageProps } from 'gatsby'

import { BadgeCheckIcon } from '@heroicons/react/outline'
import Footer from '../components/Footer'
import Header from '../components/Header'
import ContentDetails from '../components/ContentDetails'

const mainFeatureItems = [
  {
    id: 1,
    name: `Personal data`,
    description:
      `Under the EU’s General Data Protection Regulation (GDPR) personal data is defined as:
      “any information relating to an identified or identifiable natural person (‘data subject’); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person”`,
    icon: BadgeCheckIcon,
  },
  {
    id: 2,
    name: `How we use your information`,
    description:
      `This privacy notice tells you how we, Sekyee Computer Limited, may collect and use your personal data for:
      (a) administer the website.
      (b) improve your browsing experience by personalising the website.
      (c) enable your use of the services available on the website.
      (d) supply you with services.
      (e) deal with enquiries and complaints made by or about you relating to the website.`,
    icon: BadgeCheckIcon,
  },
  {
    id: 3,
    name: `Why does Sekyee Business ICT Solutions collect and store personal data?`,
    description:
      `In order for us to provide you with information on our IT Support services we may collect personal data so that we can personalise your use of the site, assist your use of the site and improve the site generally;
      - when you make an enquiry on any area of our website;
      - when we provide services to you; - when you apply for employment.
      In any event, we are committed to ensuring that the information we collect, and use is appropriate for this purpose, and does not constitute an invasion of your privacy.`,
    icon: BadgeCheckIcon,
  },
  {
    id: 4,
    name: `Will Sekyee Computer Limited share my personal data with anyone else?`,
    description:
      `Sekyee Computer Ltd do not share your personal data`,
    icon: BadgeCheckIcon,
  },
  {
    id: 5,
    name: `How will Sekyee Business ICT Solutions use the personal data it collects about me?`,
    description:
      `Sekyee Computer Ltd will process (collect, store and use) the information you provide in a manner compatible with the EU’s General Data Protection Regulation (GDPR). We will endeavour to keep your information accurate and up to date, and not keep it for longer than is necessary. Sekyee Computer Ltd is required to retain information in accordance with the law, such as information needed for income tax and audit purposes. How long certain kinds of personal data should be kept
      may also be governed by specific business-sector requirements and agreed practices. Personal data may be held in addition to these periods depending on individual business needs.`,
    icon: BadgeCheckIcon,
  },
  {
    id: 6,
    name: `Can I find out the personal data that the organisation holds about me?`,
    description:
      `Sekyee Computer Limited, at your request, can confirm what information we hold about you and how it is processed. If Sekyee Computer Limited does hold personal data about you, you can request the following information:
      • Contact details of the data protection officer, where applicable.
      • The categories of personal data collected, stored, and processed.
      • How long the data will be stored.
      • Details of your rights to correct, erase, restrict or object to such processing.
      • Information about your right to withdraw consent at any time.`,
    icon: BadgeCheckIcon,
  },
  {
    id: 7,
    name: `What forms of ID will I need to provide in order to access this?`,
    description:
      `Sekyee Computer Ltd accepts the following forms of ID when information on your personal data is requested:
      Current Passport, driving licence, birth certificate or utility bill (from last 3 months).
      Questions, comments, and requests regarding this privacy notice are welcomed and should be addressed to info@sekyee.co.uk`,
    icon: BadgeCheckIcon,
  }
]

const PrivacyPolicy = (props: PageProps) => {
  return (
    <div className="bg-white">

      <Header path={props.path} />

      <main>
        <ContentDetails 
          title="ONLINE PRIVACY STATEMENT" 
          tagline="www.sekyee.co.uk [“the Website”] is owned and operated by Sekyee Computer Limited. This policy, together with our Terms and Conditions, explain how Sekyee Computer Limited may use information we collect about you, as well as your rights over any personal information we hold about you. Please read this policy and our Terms and Conditions carefully; by accessing the Website you confirm to have understood and agreed to them."
          mainFeatures={mainFeatureItems} 
          footer="Please contact us for more information." />
      </main>
    
      <Footer />
    </div>
  )
}

export default PrivacyPolicy